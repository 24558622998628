import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { BrowserRouter, Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import { validateJWTRequest } from "../models/auth/auth";

import Auth from "../pages/Auth";
import User from "../pages/User";
import Office from "../pages/Admin/Office";
import Results from "../pages/Admin/Results";
import Moderators from "../pages/Admin/Moderators";
import EstimatedData from "../pages/Admin/EstimatedData";
import ClosedClients from "../pages/Admin/ClosedClients";
import Plan from "../pages/Admin/Plan";

function PrivateRoute({ children, ...rest }) {
  const cookies = new Cookies();
  const history = useHistory();
  const atToken = cookies.get("at");

  const validateJWT = () => {
    const atToken = cookies.get("at");

    if (!atToken) history.push("/");

    validateJWTRequest(atToken)
      .then()
      .catch((err) => {
        console.warn("Error validateJWTRequest", err);

        // Logout
        cookies.remove('at', { path: '/' });
        history.push("/");
      });
  };

  useEffect(() => {
    validateJWT();
    const intervalId = setInterval(() => {
      validateJWT();
    }, 5_000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Route
      {...rest}
      render={() =>
        atToken ? (
          children
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

function Routes() {
  return (
    <BrowserRouter>
      <RoutesContent />
    </BrowserRouter>
  );
}

function RoutesContent() {
  const history = useHistory();
  const cookies = new Cookies();
  const location = useLocation();

  const [isValidJWT, setIsValidJWT] = useState(false);

  useEffect(() => {
    const atToken = cookies.get("at");
    
    if(!atToken) return setIsValidJWT(true);
    validateJWTRequest(atToken).then(({data}) => {
      if(location.pathname.includes("/admin") && data.UserRole === 'moderator') history.push("/user");
      if(location.pathname === "/admin" || location.pathname === "/user") {
        if(data.UserRole === 'admin') {
          history.push("/admin/moderators");
        } else if(data.UserRole === 'moderator') {
          history.push("/user");
        } else {
          cookies.remove('at', { path: '/' });
          history.push("/");
        }
      }
      setIsValidJWT(true);
    })
  }, []);

  return isValidJWT && (
    <Switch>
      <Route path={`/`} exact={true}>
        <Auth />
      </Route>
      <PrivateRoute path={`/user`}>
        <User />
      </PrivateRoute>
      <PrivateRoute path={`/admin/office`}>
        <Office />
      </PrivateRoute>
      <PrivateRoute path={`/admin/moderators`}>
        <Moderators />
      </PrivateRoute>
      <PrivateRoute path={`/admin/results`}>
        <Results />
      </PrivateRoute>
      <PrivateRoute path={`/admin/estimated`}>
        <EstimatedData />
      </PrivateRoute>
      <PrivateRoute path={`/admin/closed`}>
        <ClosedClients />
      </PrivateRoute>
      <PrivateRoute path={`/admin/plan`}>
        <Plan />
      </PrivateRoute>
    </Switch>
  );
}

export default Routes;
