import React, { useEffect, useRef, useState } from "react";
import toast from 'react-hot-toast';
import { DateTime } from 'luxon';
import { Button, Col, Divider, Row } from "antd";
import dayjs from "dayjs";
import ResultChart from "../components/User/ResultChart";
import PeopleChart from "../components/User/PeopleChart";
import ClosedClients from "../components/User/ClosedClients";
import ClosedClientsModal from "../components/Modal/ClosedClientsModal";
import { AlertUi, DatePickerUi, InputNumberUi } from "../components/ui";
import { IconPresentationChart } from "../utils/Icons";
import { createClosedClientRequest, getAvailableOfficesRequest } from "../models/user/user";
import { updateOfficeDataRequest, getOfficesDailyDataRequest } from "../models/office/office";

const User = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [isDayPickerActive, setIsDayPickerActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [officesWeekData, setOfficesWeekData] = useState([]);
  const [availableOffices, setAvailableOffices] = useState(null);

  const [formResultData, setFormResultData] = useState({
    peopleCount: null,
    resultUSDT: null,
    resultRUB: null,
    withdrawal: null,
  });

  const [chartData, setChartData] = useState({
    categories: [],
    // peopleCount: [],
    // resultUSDT: [],
    // resultRUB: [],
  });

  const [formClosedClients, setFormClosedClients] = useState({
    name: "",
    phone: "",
    city: "",
    usdt: null,
    rub: null,
  });

  const inputUsers = useRef(null);

  const handleWeekChange = (date) => {
    if (date) {
      const startOfWeek = dayjs(date).startOf("week");
      const endOfWeek = dayjs(date).endOf("week");

      const daysOfWeek = [];
      for (let i = 0; i < 7; i++) {
        daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
      }

      setSelectedWeek({ start: startOfWeek, end: endOfWeek });
      setChartData({
        categories: daysOfWeek,
        // peopleCount: [5, 10, 15, 20, 25, 30, 35],
        // resultUSDT: [100, 150, 120, 200, 180, 220, 300],
        // resultRUB: [7000, 7500, 8000, 8500, 9000, 9500, 10000],
      });
      setIsDayPickerActive(true);
    } else {
      setSelectedWeek(null);
      setIsDayPickerActive(false);
    }
  };

  const disableDay = (current) => {
    if (!selectedWeek || !current) {
      return true;
    }
    return (
      current.isBefore(selectedWeek.start, "day") ||
      current.isAfter(selectedWeek.end, "day")
    );
  };

  const handleInputChange = (field, value) => {
    setFormResultData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFormClosedClients({
      name: "",
      phone: "",
      city: "",
      usdt: null,
      rub: null,
    });
  };

  const handleAddData = () => {
    const newEntry = {
      key: String(mappedDataTable.length + 1),
      ...formClosedClients,
    };

    if(!newEntry.date) return console.error('Add closed client error: missed date!')

    const date = newEntry.date.format("YYYY-MM-DD");

    if(!availableOffices) return toast.error('Вы не закреплены ни за одним офисом!')
    getOfficesDailyDataRequest(availableOffices[0]?.OfficeId, date).then(({data}) => {
      createClosedClientRequest(data.DailyData.ID, newEntry.name, newEntry.phone, newEntry.city).then(() => {
        // Close modal
        handleCancel();
        // show success message
        toast.success('Закрытый клиент успешно добавлен!')
        // reload daily data
        fetchDailyData();
      }).catch((err) => {
        console.warn("Error createClosedClientRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || "Create closed client error!"
          : "Network error";

        // Show error toast
        toast.error(errorMessage);
      });
    }).catch((err) => {
      updateOfficeDataRequest(availableOffices[0]?.OfficeId, DateTime.fromISO(date).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z")).then(() => {
        handleAddData();
      }).catch(err => {
        console.warn("Error updateOfficeDataRequest", err);

        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response
          ? err.response.data || "Update office data error"
          : "Network error";
  
        // Show error toast
        toast.error(errorMessage);
      })
    });
  };

  const setOfficesDailyData = (officeId, date) => {
    if(!officeId || !date) return console.warn('Error setOfficesDailyData: officeId or date is missing.')
    getOfficesDailyDataRequest(officeId, date).then(({data}) => {
      if(!data) return
      const dailyData = data.DailyData
    
      setOfficesWeekData(prev => {
        return [
            ...prev,
            {
              key: dailyData.ID, //"1",
              officeId: officeId,
              plan: dailyData.PlannedUsdt || 0,
              peopleCount: dailyData.OfficePeopleCount || 0,
              resultUSDT: dailyData.EarnedUsdt || 0,
              resultRUB: dailyData.EarnedRubles || 0,
              withdrawal: dailyData.WithdrawnUsdt || 0,
              peopleClosed: 0,
              closedClients: data.ClosedClients
            }
          ]
        
      })
    })
  }

  useEffect(() => {
    getAvailableOfficesRequest().then(({data}) => {
      if(data) setAvailableOffices(data);
    })
  }, [])

  useEffect(() => fetchDailyData(), [availableOffices, selectedWeek])

  const fetchDailyData = () => {
    if(!selectedWeek?.start || !availableOffices?.[0]?.OfficeId) return
    const startOfWeek = dayjs(selectedWeek.start).startOf("week");
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
    }
    if(!daysOfWeek.length) return
    setOfficesWeekData([]) // reset officesWeekData

    daysOfWeek.map(date => {
      setOfficesDailyData(availableOffices[0]?.OfficeId, date)
    })
  }

  const savePeopleHandler = () => {
    const day = DateTime.fromISO(selectedDay ? selectedDay.format("YYYY-MM-DD") : selectedWeek?.start.format("YYYY-MM-DD")).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");
    
    if(!availableOffices) return toast.error('Вы не закреплены ни за одним офисом!')

    updateOfficeDataRequest(availableOffices[0]?.OfficeId, day, undefined, formResultData.peopleCount)
    .then(() => {
      toast.success('Успешно сохранено')
    })
    .catch((err) => {
      console.warn("Error updateOfficeDataRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response
        ? err.response.data || "Update office data error"
        : "Network error";

      // Show error toast
      toast.error(errorMessage);
    });
    setFormResultData((prev) => ({
      ...prev,
      peopleCount: null, // Reset the users InputNumberUi value
    }));
    inputUsers.current.reset();
  }

  const saveUsdtRubHandler = () => {
    const day = DateTime.fromISO(selectedDay ? selectedDay.format("YYYY-MM-DD") : selectedWeek?.start.format("YYYY-MM-DD")).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");

    if(!availableOffices) return toast.error('Вы не закреплены ни за одним офисом!')
    updateOfficeDataRequest(availableOffices[0]?.OfficeId, day, undefined, undefined, formResultData.resultUSDT, formResultData.resultRUB)
    .then(() => {
      toast.success('Успешно сохранено')
    })
    .catch((err) => {
      console.warn("Error updateOfficeDataRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response
        ? err.response.data || "Update office data error"
        : "Network error";

      // Show error toast
      toast.error(errorMessage);
    });
  }

  const saveWithdrawalHandler = () => {
    const day = DateTime.fromISO(selectedDay ? selectedDay.format("YYYY-MM-DD") : selectedWeek?.start.format("YYYY-MM-DD")).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z");

    if(!availableOffices) return toast.error('Вы не закреплены ни за одним офисом!')
    updateOfficeDataRequest(availableOffices[0]?.OfficeId, day, formResultData.withdrawal)
    .then(() => {
      toast.success('Успешно сохранено')
    })
    .catch((err) => {
      console.warn("Error updateOfficeDataRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response
        ? err.response.data || "Update office data error"
        : "Network error";

      // Show error toast
      toast.error(errorMessage);
    });
  }
  
  const mappedDataTable = officesWeekData.flatMap(dayData => {
    if(dayData.closedClients) {
      return dayData.closedClients.map(client => (
        {
          key: client.ID,
          name: client.Name,
          phone: client.Number,
          city: client.City,
        }
      ))
    }
    // return empty array if closedClients isn't exist
    return [];
  })

  const weekPlan = officesWeekData?.reduce((sum, item) => sum + item.plan, 0);

  return (
    <section className="user">
      <h2>
        Добро пожаловать
        <span>
          План за неделю <b>{weekPlan}$</b>
        </span>
      </h2>
      <div className="box">
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <DatePickerUi
              name="week"
              label="Выберите неделю"
              picker="week"
              onChange={handleWeekChange}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <DatePickerUi
              name="day"
              label="Выберите дату"
              disabled={!isDayPickerActive}
              disabledDate={disableDay}
              onChange={(date) => setSelectedDay(date)}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <AlertUi
              type="info"
              text={`Выберите дату в диапазоне недели, которую вы выбрали.`}
            />

            <Row className="mt-50">
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? "inactive" : ""}`}>
                  <h4>Количество людей</h4>
                  <InputNumberUi
                    name="users"
                    label="Введите кол-во людей"
                    placeholder={10}
                    ref={inputUsers}
                    // value={formResultData.peopleCount}
                    onChange={(value) =>
                      handleInputChange("peopleCount", value)
                    }
                    errorMess={`Error`}
                  />
                  <div className="flex justify-center mt-20">
                    <Button htmlType="button" className="xs" onClick={savePeopleHandler}>
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? "inactive" : ""}`}>
                  <h4>Результат в USDT | RUB</h4>
                  <div className="amount">
                    <InputNumberUi
                      name="usdt"
                      label="Введите результат в USDT"
                      placeholder={`100$`}
                      onChange={(value) =>
                        handleInputChange("resultUSDT", value)
                      }
                      errorMess={`Error`}
                    />
                    <InputNumberUi
                      name="rub"
                      label="Введите результат в RUB"
                      placeholder={`10 000`}
                      onChange={(value) =>
                        handleInputChange("resultRUB", value)
                      }
                      errorMess={`Error`}
                    />
                  </div>
                  <div className="flex justify-center mt-20">
                    <Button htmlType="button" className="xs" onClick={saveUsdtRubHandler}>
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>

              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? "inactive" : ""}`}>
                  <h4>Вывод за неделю в USDT</h4>
                  <InputNumberUi
                    name="withdrawal"
                    label="Введите вывод"
                    placeholder={10}
                    onChange={(value) => handleInputChange("withdrawal", value)}
                    errorMess={`Error`}
                  />
                  <div className="flex justify-center mt-20">
                    <Button htmlType="button" className="xs" onClick={saveWithdrawalHandler}>
                      Сохранить
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? "inactive" : ""}`}>
                  <h4>График кол-ва людей за неделю</h4>

                  {!selectedWeek ? (
                    <div className="no-week-message">
                      <IconPresentationChart
                        width="55"
                        height="55"
                        fill="#666768"
                      />
                      Выберите неделю, чтобы увидеть графики.
                    </div>
                  ) : (
                    <div className="chart">
                      <PeopleChart chartData={chartData} officesWeekData={officesWeekData} />
                    </div>
                  )}
                </div>
              </Col>

              <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                <div className={`result ${!selectedWeek ? "inactive" : ""}`}>
                  <h4>График результата за неделю</h4>

                  {!selectedWeek ? (
                    <div className="no-week-message">
                      <IconPresentationChart
                        width="55"
                        height="55"
                        fill="#666768"
                      />
                      Выберите неделю, чтобы увидеть графики.
                    </div>
                  ) : (
                    <div className="chart">
                      <ResultChart chartData={chartData} officesWeekData={officesWeekData} />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div className="closed-clients">
                  <h4>
                    Закрытые клиенты
                    <Button
                      htmlType="button"
                      className="xs"
                      onClick={showModal}
                    >
                      Добавить Клиента
                    </Button>
                  </h4>
                  <ClosedClients data={mappedDataTable} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Modal Closed Clients */}
        <ClosedClientsModal
          isModalOpen={isModalOpen}
          handleAddData={handleAddData}
          handleCancel={handleCancel}
          setFormClosedClients={setFormClosedClients}
        />
      </div>
    </section>
  );
};

export default User;
