import { Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from 'luxon';
import { Layout } from "../../components/Layout";
import { IconUserTimes } from "../../utils/Icons";
import { getOfficeRequest, getAllOfficesDailyDataRequest } from "../../models/office/office";

const ClosedClients = () => {
  const [mappedDataClosed, setMappedDataClosed] = useState([]);
  const [officesList, setOfficesList] = useState(null);

  useEffect(() => {
    getOfficeRequest().then(({data}) => {
      setOfficesList(data);
    })
  }, [])

  useEffect(() => {
    if(!officesList) return
    const startOfWeek = dayjs().startOf("week");
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
    }
    setMappedDataClosed([]) // reset mapped data
    officesList.map(office => {
        getAllOfficesDailyDataRequest(office.ID).then(({data}) => {
          data.ClosedClients.map(client => {
            const date = data.DailyData.find(dailyItem => dailyItem.ID === client.OfficeDailyDataId)?.Day;
            const officeId = data.DailyData.find(dailyItem => dailyItem.ID === client.OfficeDailyDataId)?.OfficeId;

            setMappedDataClosed(prev => ([
              ...prev,
              {
                key: office.ID,
                name: officesList.find(office => office.ID === officeId)?.Name || "-",
                userName: client.Name,
                number: client.Number,
                date: date ? DateTime.fromISO(date).toFormat("yyyy-MM-dd") : "-",
              }
            ]))
          })
        }).catch(err => {
          console.warn(err);
        })
    })
  }, [officesList])

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Имя клиента`,
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: `Номер телефона`,
      dataIndex: "number",
      key: "number",
    },
    {
      title: `Дата`,
      dataIndex: "date",
      key: "date",
    },
  ];

  // const mappedDataClosed = [
  //   {
  //     key: "1",
  //     name: "Офис №1",
  //     userName: `Иван Иванов`,
  //     number: `+ 7 495 111 11 11`,
  //     date: `2024-12-01`,
  //   },
  // ];

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`closed`}>
          <h4 className="title">
            <span>
              <IconUserTimes width="25" height="25" fill="#00c39a" />
              Закрытые клиенты
            </span>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataClosed ? mappedDataClosed : []}
            columns={columns}
            scroll={{ x: 768 }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ClosedClients;
