import axios from "axios";
import Cookies from 'universal-cookie';
import { apiDomain } from "../../config/consts";

export const createModeratorRequest = async (Login, Password) => {
  const cookies = new Cookies();
  const atToken = cookies.get("at");

  if (Login, Password) {
    return axios.post(`${apiDomain}/create-moderator`, 
    {
      Login,
      Password
    },
    { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
  } else {
    console.error('Create moderator: some of reuired data is missed.')
  }
};

export const getModeratorsRequest = async () => {
    const cookies = new Cookies();
    const atToken = cookies.get("at");

    return axios.get(`${apiDomain}/moderators`, { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
};

export const getModeratorsAccessRequest = async (moderatorId) => {
    const cookies = new Cookies();
    const atToken = cookies.get("at");

    return axios.get(`${apiDomain}/moderator-access/${moderatorId}`, { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
};

export const deleteModeratorRequest = async (id) => {
  const cookies = new Cookies();
  const atToken = cookies.get("at");

  if (id) {
    return axios.delete(`${apiDomain}/moderator/${id}`, 
    // null,
    { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
  } else {
    console.error('Delete moderator: some of reuired data is missed.')
  }
};

export const unconnectModeratorWithOfficeRequest = async (OfficeId, ModeratorId) => {
  const cookies = new Cookies();
  const atToken = cookies.get("at");

  if (OfficeId && ModeratorId ) {
    return axios.delete(`${apiDomain}/unconnect-moderator-with-office`, 
    {
      data: {
        OfficeId,
        ModeratorId
      },
      headers: {
        'Authorization': `Bearer ${atToken}`
      }
    })
  } else {
    console.error('Unconnect moderator with office: some of reuired data is missed.')
  }
};