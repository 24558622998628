import { Button, Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Layout } from "../../components/Layout";
import { IconEdit, IconHome, IconTrash } from "../../utils/Icons";
import AddOfficeModal from "../../components/Modal/AddOfficeModal";
import DeleteOfficeModal from "../../components/Modal/DeleteOfficeModal";
import SelectionModeratorModal from "../../components/Modal/SelectionOfficeModal";
import {
  createOfficeRequest,
  getOfficeRequest,
  deleteOfficeRequest,
  getOfficesAccessRequest,
} from "../../models/office/office";
import {
  getModeratorsRequest,
} from "../../models/moderators/moderators";

const Office = () => {
  const [isModalAddOffice, setIsModalAddOffice] = useState(false);
  const [isModalDeleteOffice, setIsModalDeleteOffice] = useState(false);
  const [isModalEditModerator, setIsModalEditModerator] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState(undefined);
  const [mappedDataOffice, setMappedDataOffice] = useState([]);

  const [moderatorsList, setModeratorsList] = useState(null);
  const [officesAccessList, setOfficesAccessList] = useState(null);
  const [choosedOfficesForDelete, setChoosedOfficesForDelete] = useState(null);

  const [formAddOffice, setFormAddOffice] = useState({
    name: "",
    moderator: "",
    plan: null,
  });

  const showModalAddOffice = () => {
    setIsModalAddOffice(true);
  };

  const showModalDeleteOffice = (record) => {
    setChoosedOfficesForDelete([record.key])
    setIsModalDeleteOffice(true);
  };

  const showModalEditModerator = (record) => {
    setSelectedOffice(record.key);
    setIsModalEditModerator(true);
  };

  const handleCancelAddOffice = () => {
    setIsModalAddOffice(false);
  };

  const handleCancelDeleteOffice = () => {
    setChoosedOfficesForDelete(null)
    setIsModalDeleteOffice(false);
  };

  const handleSubmitDeleteOffice = () => {
    if(!choosedOfficesForDelete) return
    choosedOfficesForDelete?.map(office => {
      deleteOfficeRequest(office).then(() => {
        setIsModalDeleteOffice(false);
        setMappedDataOffice(prev => prev.filter((item) => item.key !== office))
      }).catch(err => {
        console.warn('Delete office (deleteOfficeRequest) error', err)
  
        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response ? err.response.data.message || "Delete office error" : "Network error";
    
        // Show error toast
        toast.error(errorMessage);
      })
    })
  };

  const handleCancelEditModerator = () => {
    setSelectedOffice(null);
    setIsModalEditModerator(false);
  };

  const handleAddOffice = () => {
    const newEntry = {
      key: String(mappedDataOffice.length + 1),
      ...formAddOffice,
    };

    if(!newEntry.name) return console.error('You don\'t set name of the office!')
    createOfficeRequest(newEntry.name).then(r => {
      // setMappedDataOffice((prev) => [...prev, newEntry]);
      fetchOffices();
    }).catch(err => {
      console.warn("Error createOfficeRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Create office error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    })
  };

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Модератор`,
      dataIndex: "moderator",
      key: "moderator",
      render: (moderator, record) => (
        <Button type="text" onClick={() => showModalEditModerator(record)}>
          {moderator || "Укажите модератора"}
          <IconEdit width="20" height="20" fill="#00c39a" />
        </Button>
      ),
    },
    {
      title: `План`,
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: `Действия`,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Button type="text" onClick={() => showModalDeleteOffice(record)}>
          <IconTrash width="20" height="20" fill="#d94c48" />
        </Button>
      ),
      width: "5%",
    },
  ];

  const fetchOffices = () => {
    getOfficeRequest().then(({data}) => {
      // get all offices access
      if(!data) return
      const formatedResponse = data.map(item => {
        return ({
          key: item.ID,
          name: item.Name,
          moderator: null,
        });
      });
      setMappedDataOffice(formatedResponse)

      data.map(office => {
        if(!office.ID) return
        getOfficesAccessRequest(office.ID).then(({data}) => {
          if(data) {
            const moderatorIds = data.map(offices => offices.UserId)
            const moderatorNames = moderatorIds && moderatorIds ? moderatorIds.map(moderatorId => {
              return moderatorsList?.find(moderator => moderator.ID === moderatorId)?.Login
            }).join(', ') : "-"
            
            setMappedDataOffice((prev) =>
              prev.map((el) => {
                return (el.key === office.ID ? { ...el, moderator: moderatorNames } : el)
              })
            );
          }
        })
      })
    })
  }

  useEffect(() => {
    fetchOffices()
  }, [moderatorsList])

  useEffect(() => {
    // get all moderators
    getModeratorsRequest().then(({data}) => {
      setModeratorsList(data)
    })
  }, []);

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`office`}>
          <h4 className="title">
            <span>
              <IconHome width="25" height="25" fill="#00c39a" />
              Офиса
            </span>

            <Button
              htmlType="button"
              onClick={showModalAddOffice}
              className="xs"
            >
              Добавить Офис
            </Button>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataOffice}
            columns={columns}
            scroll={{ x: 768 }}
          />
        </div>

        {/* Modal Delete Office */}
        <DeleteOfficeModal
          isModalOpen={isModalDeleteOffice}
          officesList={mappedDataOffice}
          choosedOfficesForDelete={choosedOfficesForDelete}
          handleCancel={handleCancelDeleteOffice}
          handleSubmit={handleSubmitDeleteOffice}
        />

        {/* Modal Edit Moderator */}
        <SelectionModeratorModal
          isModalOpen={isModalEditModerator}
          selectedOffice={selectedOffice}
          fetchOffices={fetchOffices}
          handleCancel={handleCancelEditModerator}
        />

        {/* Modal Add Office */}
        <AddOfficeModal
          isModalOpen={isModalAddOffice}
          handleAddData={handleAddOffice}
          handleCancel={handleCancelAddOffice}
          setFormAddOffice={setFormAddOffice}
        />
      </div>
    </Layout>
  );
};

export default Office;
