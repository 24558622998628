import { Button, Divider, Table } from "antd";
import { DateTime } from 'luxon';
import React, { useEffect, useState } from "react";
import { Layout } from "../../components/Layout";
import { IconClipboardList } from "../../utils/Icons";

import EditPlanModal from "../../components/Modal/EditPlanModal";
import { AlertUi } from "../../components/ui";

import { getOfficeRequest, getAllOfficesDailyDataRequest } from "../../models/office/office";

const Plan = () => {
  const [isModalEditPlan, setIsModalEditPlan] = useState(false);

  const [officesList, setOfficesList] = useState(null);
  // const [officesDailyData, setOfficesDailyData] = useState(null);

  const showModalEditPlan = () => {
    setIsModalEditPlan(true);
  };

  const handleCancelEditPlan = () => {
    setIsModalEditPlan(false);
  };

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Неделя`,
      dataIndex: "week",
      key: "week",
    },
    {
      title: `План`,
      dataIndex: "plan",
      key: "plan",
    },
  ];

  const [mappedDataOffice, setMappedDataOffice] = useState([]);

  useEffect(() => {
    getOfficeRequest().then(({data}) => setOfficesList(data));
  }, [])

  const fetchPlan = () => {
    if(!officesList) return
    setMappedDataOffice([])
    officesList.map(office => {
      getAllOfficesDailyDataRequest(office.ID).then(({data}) => {
        if(data) {
          const dailyData = data.DailyData

          dailyData.map(item => {
              const date = DateTime.fromISO(item.Day).startOf('day');
              setMappedDataOffice(prev => ([...prev, {
                key: item.ID,
                officeId: item.OfficeId,
                name: officesList.find(office => office.ID === item.OfficeId)?.Name,
                week: `${date.year}-${date.weekNumber}`,
                plan: item.PlannedUsdt
              }]))
            }
          )
        }
      }).catch(err => console.warn('getAllOfficesDailyDataRequest error: ', err))
    })
  }

  useEffect(() => fetchPlan(), [officesList]);

  const mappedDataOfficeMerged = mappedDataOffice.sort((a, b) => a.key - b.key).filter(dailyData => dailyData.plan);

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`office`}>
          <h4 className="title">
            <span>
              <IconClipboardList width="25" height="25" fill="#00c39a" />
              План
            </span>

            <Button
              htmlType="button"
              onClick={showModalEditPlan}
              className="xs"
            >
              Добавить План
            </Button>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataOfficeMerged}
            columns={columns}
            scroll={{ x: 768 }}
          />
          <AlertUi type="info" text={`План устанавливается на неделю.`} />
        </div>

        {/* Modal Edit Office */}
        <EditPlanModal
          isModalOpen={isModalEditPlan}
          fetchPlan={fetchPlan}
          handleCancel={handleCancelEditPlan}
        />
      </div>
    </Layout>
  );
};

export default Plan;
