import React from "react";
import { Button, Modal } from "antd";
import { IconClose } from "../../utils/Icons";

const DeleteModeratorModal = ({
  isModalOpen,
  choosedModeratorsForDelete,
  moderatorsList,
  handleCancel,
  handleSubmit,
}) => {
  return (
    <Modal
      title="Удалить модератора"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <div className="text-modal">
        Вы подтверждаете удаление модератора <b>{choosedModeratorsForDelete && moderatorsList && moderatorsList?.find(moderator => moderator.key === choosedModeratorsForDelete[0])?.name}</b> ?
      </div>
      <div className="flex justify-center gap-10 mt-30">
        <Button onClick={handleSubmit} className="xs">
          Удалить
        </Button>
        <Button onClick={handleCancel} className="xs">
          Закрыть
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModeratorModal;
