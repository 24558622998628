import React, {useState, useRef, forwardRef, useImperativeHandle} from "react";
import { Form, InputNumber } from "antd";
import { IconAngleDownSquare, IconAngleUpSquare } from "../../../utils/Icons";

const InputNumberUi = forwardRef(({
  name,
  id,
  label,
  errorMess,
  formItemClassName,
  required = true,
  // value,
  ...props
}, ref) => {
  // const [form] = Form.useForm();
  // const inputRef = useRef(null)
  // const [value, setValue] = useState(null);
  
  useImperativeHandle(ref, () => ({
    reset: () => {},
  }));
  
  return (
    // <Form form={form}>
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: errorMess }]}
      className={formItemClassName}
    >
      <InputNumber
        id={id}
        {...props}
        controls={{
          upIcon: <IconAngleUpSquare width="14" height="14" fill="#00c39a" />,
          downIcon: (
            <IconAngleDownSquare width="14" height="14" fill="#00c39a" />
          ),
        }}
      />
    </Form.Item>
    // </Form>
  );
});

export default InputNumberUi;
