import React from "react";

const IconPresentationChart = ({
  width = "24px",
  height = "24px",
  fill = "#000",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      {...props}
    >
      <g>
        <g id="presentation-chart-line">
          <path
            fill={fill}
            opacity={0.3}
            d="M20,3.75V13c0,2.29-1.21,3.5-3.5,3.5h-9C5.21,16.5,4,15.29,4,13V3.75Z"
          />
          <path
            fill={fill}
            d="M21,3.75H3a.75.75,0,0,1,0-1.5H21a.75.75,0,0,1,0,1.5Z"
          />
          <path
            fill={fill}
            d="M15.62,21.42a.751.751,0,0,1-.62.33.741.741,0,0,1-.42-.13L12,19.9,9.42,21.62a.749.749,0,0,1-.84-1.24l2.67-1.78V16.5h1.5v2.1l2.67,1.78A.751.751,0,0,1,15.62,21.42Z"
          />
          <path
            fill={fill}
            d="M8,11.85a.75.75,0,0,1-.53-1.28L9.7,8.337a1.552,1.552,0,0,1,2.191,0l1.271,1.269L15.47,7.37a.75.75,0,0,1,1.06,1.06L14.3,10.665a1.55,1.55,0,0,1-2.19,0L10.833,9.4,8.53,11.63A.744.744,0,0,1,8,11.85Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default IconPresentationChart;
