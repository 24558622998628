import React, { useState, useEffect } from "react";
import toast from 'react-hot-toast';
import { DateTime } from 'luxon';
import { Button, Divider, Table } from "antd";
import { Layout } from "../../components/Layout";
import {
  IconClipboardTimes,
  IconTrash,
  IconUsersGroup,
} from "../../utils/Icons";
import AddModeratorModal from "../../components/Modal/AddModeratorModal";
import { getOfficeRequest } from "../../models/office/office";
import {
  createModeratorRequest,
  getModeratorsRequest,
  getModeratorsAccessRequest,
  deleteModeratorRequest,
} from "../../models/moderators/moderators";
import { getAllHistoryRequest, getHistoryLengthRequest } from "../../models/user/user";
import DeleteModeratorModal from "../../components/Modal/DeleteModeratorModal";

const Moderators = () => {
  const [isModalAddModerator, setIsModalAddModerator] = useState(false);
  const [isModalDeleteModerator, setIsModalDeleteModerator] = useState(false);
  const [choosedModeratorsForDelete, setChoosedModeratorsForDelete] = useState(null);
  const [mappedDataModerators, setMappedDataModerators] = useState([]);

  const [mappedDataHistory, setMappedDataHistory] = useState([]);
  const [historyPaginationData, setHistoryPaginationData] = useState({
    length: null,
    pageLimit: 10
  })
  const [currentPage, setCurrentPage] = useState(1);
  const totalHistoryPages = historyPaginationData.length ? Math.ceil(historyPaginationData.length / historyPaginationData.pageLimit) : 0;

  const [formAddModerators, setFormAddModerators] = useState({
    name: "",
    moderator: "",
    plan: null,
  });

  const [officesList, setOfficesList] = useState(null);

  const showModalAddModerator = () => {
    setIsModalAddModerator(true);
  };

  const showModalDeleteModerator = (record) => {
    setChoosedModeratorsForDelete([record.key]);
    setIsModalDeleteModerator(true);
  };

  const handleCancelModalAddModerator = () => {
    setIsModalAddModerator(false);
  };

  const handleCancelModalDeleteModerator = () => {
    setChoosedModeratorsForDelete(null);
    setIsModalDeleteModerator(false);
  };

 const handleAddModerator = () => {
    const newEntry = {
      key: String(mappedDataModerators.length + 1),
      office: "-",
      ...formAddModerators,
    };

    if ((newEntry.name, newEntry.password)) {
      createModeratorRequest(newEntry.name, newEntry.password)
        .then(() => {
          // setMappedDataModerators((prev) => [...prev, newEntry]);
          fetchModerators();
          handleCancelModalAddModerator();
        })
        .catch((err) => {
          console.warn("Error createModeratorRequest", err);

          // Check if the error is an Axios error to get a more descriptive message
          if(err.response?.data === "invalid length of password or login") {
            // Show error toast
            toast.error("Логин должен содержать более 1 символа, а пaроль более 4 символов!");
          } else {
            const errorMessage = err.response
              ? err.response.data || "Create moderator error"
              : "Network error";
  
            // Show error toast
            toast.error(errorMessage);
          }
        });
    }
  };

  const columns = [
    {
      title: `Модератор (Логин)`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `Офис`,
      dataIndex: "office",
      key: "office",
    },
    {
      title: `Действия`,
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Button type="text" onClick={() => showModalDeleteModerator(record)}>
          <IconTrash width="20" height="20" fill="#d94c48" />
        </Button>
      ),
      width: "5%",
    },
  ];

  const historyDefinitions = {
    UpdateOfficeDailyDataFromModerator: "Обновил данные офиса за период",
    UpdateClosedClientFromModerator: "Обновил закрытых клиентов",
  }

  const handleSubmitDeleteModerator = () => {
    if(!choosedModeratorsForDelete) return
    choosedModeratorsForDelete?.map(moderator => {
      deleteModeratorRequest(moderator).then(() => {
        setIsModalDeleteModerator(false);
        setMappedDataModerators(prev => prev.filter((item) => item.key !== moderator))
      }).catch(err => {
        console.warn('Delete moderators (deleteModeratorRequest) error', err)
  
        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response ? err.response.data.message || "Delete moderators error" : "Network error";
    
        // Show error toast
        toast.error(errorMessage);
      })
    })
  };

  const fetchModerators = () => {
    // get all moderators
    getModeratorsRequest().then(({ data }) => {
      if (!data) return;
      setMappedDataModerators(
        data.map((el) => ({
          key: el.ID,
          name: el.Login,
          office: null,
        }))
      );
    }).catch(err => {
      console.warn("Error getModeratorsRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Get moderators error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    });
  }

  useEffect(() => {
    // get all offices
    getOfficeRequest().then(({ data }) => setOfficesList(data)).catch(err => {
      console.warn("Error getOfficeRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Get office error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    });

    fetchModerators();

    // get history length
    getHistoryLengthRequest().then(({data}) => {
      setHistoryPaginationData(prev =>({
        ...prev,
        length: data
      }))
    })
  }, []);

  useEffect(() => {
    // get all history by each moderator
    if(!officesList) return
    setMappedDataHistory([]);
    getAllHistoryRequest(currentPage).then(({data}) => {
      if(!data) return
      data.map(action => {
        setMappedDataHistory(prev => [
          ...prev,
          {
            name: action.UserLogin,
            office: officesList && officesList.find(office => office.ID === action.Description.OfficeId)?.Name || action.Description.OfficeId,
            change: historyDefinitions[action.Action] || action.Action,
            date: action.CreatedAt ? DateTime.fromISO(action.CreatedAt).toFormat("yyyy-MM-dd") : "-"
          }
        ])
      })
    }).catch(err => {
      console.warn("Error getAllHistoryRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Get user history error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    });
  }, [officesList, currentPage])

  useEffect(() => {
    // get moderator access (for instance: offices)
    mappedDataModerators.map(({key: moderatorId, office}) => {
      if(office) return
      getModeratorsAccessRequest(Number(moderatorId)).then(({data}) => {
        if(!mappedDataModerators || !officesList) return
        const officeNames = data ? data.map(obj => officesList.find(office => office.ID === obj.OfficeId).Name).join(", ") : "-"

        setMappedDataModerators((prev) =>
          prev.map((el) =>
            el.key === moderatorId ? { ...el, office: officeNames } : el
          )
        );
      }).catch(err => {
        console.warn("Error getModeratorsAccessRequest", err);
  
        // Check if the error is an Axios error to get a more descriptive message
        const errorMessage = err.response ? err.response.data.message || "Get moderators access error" : "Network error";
    
        // Show error toast
        toast.error(errorMessage);
      });
    });
  }, [mappedDataModerators, officesList]);


  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`moderator`}>
          <h4 className="title">
            <span>
              <IconUsersGroup width="25" height="25" fill="#00c39a" />
              Модераторы
            </span>

            <Button
              htmlType="button"
              onClick={showModalAddModerator}
              className="xs"
            >
              Добавить Модератора
            </Button>
          </h4>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mappedDataModerators}
            columns={columns}
          />

          <Divider />

          <h5>История изменений</h5>
          <div className="historyWrapper">
            <div className="history">
              {mappedDataHistory.length > 0 ? (
                mappedDataHistory.map((item, index) => (
                  <div className="item" key={index}>
                    <div className="name">
                      <span className="name-subtitle">Дата</span>
                      <span className="name-title">{item.date}</span>
                    </div>
                    <div className="name">
                      <span className="name-subtitle">Модератор (Офис)</span>
                      <span className="name-title">
                        {item.name} ({item.office ? item.office : '-'})
                      </span>
                    </div>
                    <div className="name">
                      <span className="name-subtitle">Изменение</span>
                      <span className="name-title">{item.change}</span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-message">
                  <div className="message">
                    <IconClipboardTimes width="45" height="45" fill="#00c39a" />
                    История изменений пуста
                  </div>
                </div>
              )}
            </div>
            {
              mappedDataHistory.length > 0 && historyPaginationData.length && totalHistoryPages > 1 &&
                <div className="pagination">
                  <ul className="pagination__list">
                      <button className="pagination__item" onClick={() => setCurrentPage(currentPage - 1)} disabled={!(currentPage > 1)}>
                        <span className="pagination__button pagination__navigationButton pagination__previousButton"><svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg></span>
                      </button>
                    {
                      Array.from({ length: totalHistoryPages }, (_, index) => index + 1).map((pageNumber) => (
                        <li className={currentPage === pageNumber ? "pagination__item pagination__item__active" : "pagination__item"}><button className="pagination__button" onClick={() => setCurrentPage(pageNumber)}>{pageNumber}</button></li>
                      ))
                    }
                      <button className="pagination__item" onClick={() => setCurrentPage(currentPage + 1)} disabled={!(totalHistoryPages > currentPage)}>
                        <span className="pagination__button pagination__navigationButton pagination__nextButton"><svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg></span>
                      </button>
                  </ul>
                </div>
            }
          </div>
        </div>

        {/* Modal Moderator Office */}
        <DeleteModeratorModal
          isModalOpen={isModalDeleteModerator}
          moderatorsList={mappedDataModerators}
          choosedModeratorsForDelete={choosedModeratorsForDelete}
          handleCancel={handleCancelModalDeleteModerator}
          handleSubmit={handleSubmitDeleteModerator}
        />

        {/* Modal Add Moderator */}
        <AddModeratorModal
          isModalOpen={isModalAddModerator}
          handleAddData={handleAddModerator}
          handleCancel={handleCancelModalAddModerator}
          setFormAddOffice={setFormAddModerators}
        />
      </div>
    </Layout>
  );
};

export default Moderators;
