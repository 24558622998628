import React from "react";
import ReactApexChart from "react-apexcharts";

const PeopleChart = ({ chartData, officesWeekData, officesList }) => {
  if(!officesWeekData || !officesList) return

  const peopleChartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: "Количество людей",
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ["#00c39a"],
    legend: {
      position: "top",
    },
  };

  const officesWeekDataSeriesFormat = officesList.map((item) => 
    ({
      name: `Количество людей: ${item.Name}`,
      data: officesWeekData.filter(dataItem => item.ID === dataItem.officeId).map(data => data.peopleCount && data.peopleCount)
    })
  )
  const officesWeekDataSeriesMaxLength = Math.max(...officesWeekDataSeriesFormat.map(office => office.data.length));
  const officesWeekDataSeriesFormatSum = Array.from({ length: officesWeekDataSeriesMaxLength }, (_, i) =>
    officesWeekDataSeriesFormat.reduce((sum, office) => sum + (office.data[i] || 0), 0)
  );

  const peopleChartSeries = [
    {
      name: "Количество людей",
      data: officesWeekDataSeriesFormatSum,
    },
  ];

  return (
    <ReactApexChart
      options={peopleChartOptions}
      series={peopleChartSeries}
      type="line"
      height={350}
    />
  );
};

export default PeopleChart;
