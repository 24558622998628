import axios from "axios";
import Cookies from 'universal-cookie';
import { apiDomain } from "../../config/consts";

export const getUserHistoryRequest = async (userId) => {
  const cookies = new Cookies();
  const atToken = cookies.get("at");

  return axios.get(`${apiDomain}/user-history/${userId}`, { headers: { 
    'Authorization': `Bearer ${atToken}` 
    }
  })
};

export const getAllHistoryRequest = async (page = 1, limit = 10) => {
    const cookies = new Cookies();
    const atToken = cookies.get("at");

    return axios.get(`${apiDomain}/all-history?page=${page}&limit=${limit}`, { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
};

export const getHistoryLengthRequest = async () => {
    const cookies = new Cookies();
    const atToken = cookies.get("at");

    return axios.get(`${apiDomain}/history-length`, { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
};

export const getAvailableOfficesRequest = async () => {
    const cookies = new Cookies();
    const atToken = cookies.get("at");

    return axios.get(`${apiDomain}/available-offices`, { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    })
};

export const createClosedClientRequest = async (OfficeDailyDataId, Name, Number, City) => {
  const cookies = new Cookies();
  const atToken = cookies.get("at");

  if (OfficeDailyDataId && Name && Number && City) {
    return axios.post(`${apiDomain}/create-closed-client`, 
    {
      OfficeDailyDataId,
      Name,
      Number,
      City
    },
    { headers: { 
      'Authorization': `Bearer ${atToken}` 
      }
    }
  )
  } else {
    console.error('Create closed client: some of reuired data is missed.')
  }
};