import React, { useState } from "react";
import SideBar from "./SideBar";
import { Button } from "antd";
import { IconMenuLeftSquare, IconTimesSquare } from "../utils/Icons";

export const Layout = ({ children, section, exact = true }) => {
  const [isSidebarActive, setSidebarActive] = useState(false);

  const toggleSidebar = () => {
    setSidebarActive(!isSidebarActive);
  };

  return (
    <>
      <section className={section}>
        <SideBar isActive={isSidebarActive} toggleSidebar={toggleSidebar} />
        <Button
          onClick={toggleSidebar}
          className={`toggle-sidebar-btn ${isSidebarActive ? "active" : ""}`}
        >
          {isSidebarActive ? (
            <IconTimesSquare width="24" height="24" fill="#00c39a" />
          ) : (
            <IconMenuLeftSquare width="24" height="24" fill="#00c39a" />
          )}
        </Button>
        {children}
      </section>
    </>
  );
};
