import React from "react";
import ReactApexChart from "react-apexcharts";

const PeopleChart = ({ chartData, officesWeekData }) => {
  const peopleChartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: "Количество людей",
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ["#00c39a"],
    legend: {
      position: "top",
    },
  };
  
  officesWeekData.sort((a, b) => a.key - b.key)
  
  const peopleChartSeries = [
    {
      name: "Количество людей",
      data: officesWeekData.map(data => data.peopleCount),
    },
  ];

  return (
    <ReactApexChart
      options={peopleChartOptions}
      series={peopleChartSeries}
      type="line"
      height={350}
    />
  );
};

export default PeopleChart;
