import React from "react";
import ReactApexChart from "react-apexcharts";

const ResultChart = ({ chartData, officesWeekData }) => {
  const resultChartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: "Результаты",
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ["#00c39a", "#008FFB"],
    legend: {
      position: "top",
    },
  };

  officesWeekData.sort((a, b) => a.key - b.key)

  const resultChartSeries = [
    {
      name: "Результат в USDT",
      data: officesWeekData.map(data => data.resultUSDT),
    },
    {
      name: "Результат в RUB",
      data: officesWeekData.map(data => data.resultRUB),
    },
  ];

  return (
    <ReactApexChart
      options={resultChartOptions}
      series={resultChartSeries}
      type="line"
      height={350}
    />
  );
};

export default ResultChart;
