import React from "react";
import ReactApexChart from "react-apexcharts";

const ResultChart = ({ chartData, officesWeekData, officesList }) => {
  const resultChartOptions = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.categories,
    },
    yaxis: {
      title: {
        text: "Результаты",
      },
      labels: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    markers: {
      size: 4,
    },
    colors: ["#00c39a", "#008FFB"],
    legend: {
      position: "top",
    },
  };

  const officesWeekDataSeriesFormat = officesList.map((item) => 
    ({
      name: `Количество людей: ${item.Name}`,
      data: officesWeekData.filter(dataItem => item.ID === dataItem.officeId).map(data => (data.resultUSDT && data.resultRUB) && {resultUSDT: data.resultUSDT, resultRUB: data.resultRUB})
    })
  )

  const combinedData = [];
  const maxLength = Math.max(
    ...officesWeekDataSeriesFormat.map((office) => office.data.length)
  );
  // sum iteration
  for (let i = 0; i < maxLength; i++) {
    const combined = { resultUSDT: 0, resultRUB: 0 };
    officesWeekDataSeriesFormat.forEach((office) => {
      if (office.data[i]) {
        combined.resultUSDT += office.data[i].resultUSDT;
        combined.resultRUB += office.data[i].resultRUB;
      }
    });
    combinedData.push(combined);
  }
  
  const resultChartSeries = [
    {
      name: "Результат в USDT",
      data: combinedData.length ? combinedData.map(result => result.resultUSDT) : [],
    },
    {
      name: "Результат в RUB",
      data: combinedData.length ? combinedData.map(result => result.resultRUB) : [],
    },
  ];

  return (
    <ReactApexChart
      options={resultChartOptions}
      series={resultChartSeries}
      type="line"
      height={350}
    />
  );
};

export default ResultChart;
