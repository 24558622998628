import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DateTime } from 'luxon';
import { Col, Divider, Row, Select, Table } from "antd";
import { Layout } from "../../components/Layout";
import { AlertUi, DatePickerUi, SelectUi } from "../../components/ui";
import { IconPresentationChart } from "../../utils/Icons";
import PeopleChart from "../../components/Admin/PeopleChart";
import ResultChart from "../../components/Admin/ResultChart";
import { getOfficeRequest, getOfficesDailyDataRequest } from "../../models/office/office";

const { Option } = Select;

const Results = () => {
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [isDayPickerActive, setIsDayPickerActive] = useState(false);

  const [officesList, setOfficesList] = useState(null);
  const [officeDailyData, setOfficeDailyData] = useState(null);
  const [officesWeekData, setOfficesWeekData] = useState([]);
  const [commonStatistic, setCommonStatistic] = useState(null);

  const [chartData, setChartData] = useState({
    categories: [],
    // peopleCount: [],
    // resultUSDT: [],
    // resultRUB: [],
  });

  const handleWeekChange = (date) => {
    if (date) {
      const startOfWeek = dayjs(date).startOf("week");
      const endOfWeek = dayjs(date).endOf("week");

      const daysOfWeek = [];
      for (let i = 0; i < 7; i++) {
        daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
      }

      setSelectedWeek({ start: startOfWeek, end: endOfWeek });
      setChartData({
        categories: daysOfWeek,
        // peopleCount: [[15, 20, 25, 30, 35, 40, 45], [5, 10, 15, 20, 25, 30, 35]],
        // resultUSDT: [100, 150, 120, 200, 180, 220, 300],
        // resultRUB: [7000, 7500, 8000, 8500, 9000, 9500, 10000],
      });
      setIsDayPickerActive(true);
    } else {
      setSelectedWeek(null);
      setIsDayPickerActive(false);
    }
  };

  const disableDay = (current) => {
    if (!selectedWeek || !current) {
      return true;
    }
    return (
      current.isBefore(selectedWeek.start, "day") ||
      current.isAfter(selectedWeek.end, "day")
    );
  };

  const columns = [
    {
      title: `Название офиса`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: `План`,
      dataIndex: "plan",
      key: "plan",
    },
    {
      title: `Кол-во людей`,
      dataIndex: "peopleCount",
      key: "peopleCount",
    },
    {
      title: `Результат (USDT)`,
      dataIndex: "resultUSDT",
      key: "resultUSDT",
    },
    {
      title: `Результат (RUB)`,
      dataIndex: "resultRUB",
      key: "resultRUB",
    },
    {
      title: `Вывод (USDT)`,
      dataIndex: "withdrawal",
      key: "withdrawal",
    },
    {
      title: `Кол-во закрытых клиентов`,
      dataIndex: "peopleClosed",
      key: "peopleClosed",
    },
  ];

  // const mappedDataResult = [
  //   {
  //     key: "1",
  //     name: "Офис №1",
  //     plan: 50000,
  //     peopleCount: 20,
  //     resultUSDT: 40000,
  //     resultRUB: 400000,
  //     peopleClosed: 20,
  //   },
  // ];

  useEffect(() => {
    getOfficeRequest().then(({data}) => setOfficesList(data))
  }, [])

  const setOfficesDailyData = (officeId, date) => {
    if(!officeId || !date) return console.warn('Error setOfficesDailyData: officeId or date is missing.')
    getOfficesDailyDataRequest(officeId, date).then(({data}) => {
      if(!data) return
    
      setOfficesWeekData(prev => {
        const officeName = officesList.find(item => item.ID === data.DailyData.OfficeId)?.Name

        return [
            ...prev,
            {
              key: data.DailyData.ID, //"1",
              officeId: officeId,
              name: officeName || data.DailyData.OfficeId,
              plan: data.DailyData.PlannedUsdt || 0,
              peopleCount: data.DailyData.OfficePeopleCount || 0,
              resultUSDT: data.DailyData.EarnedUsdt || 0,
              resultRUB: data.DailyData.EarnedRubles || 0,
              withdrawal: data.DailyData.WithdrawnUsdt || 0,
              peopleClosed: data.ClosedClients?.length || 0,
            }
          ]
        
      })
    }).catch(err => console.warn('getOfficesDailyDataRequest error', err))
  }

  useEffect(() => {
    if(!selectedWeek?.start) return
    const startOfWeek = dayjs(selectedWeek.start).startOf("week");
    const daysOfWeek = [];
    for (let i = 0; i < 7; i++) {
      daysOfWeek.push(startOfWeek.add(i, "day").format("YYYY-MM-DD"));
    }
    if(!daysOfWeek.length) return
    setOfficesWeekData([]) // reset officesWeekData

    if(officesList && selectedWeek && !selectedDay && !selectedOffice) {
      officesList.map(office => {
        daysOfWeek.map(date => { // there we will need a reducer if plan will set on several days in one week
          setOfficesDailyData(office.ID, date);
        })
      })
    } else if(officesList && selectedWeek && !selectedDay && selectedOffice) {
      daysOfWeek.map(date => { // there we will need a reducer if plan will set on several days in one week
        setOfficesDailyData(selectedOffice, date);
      })
    } else if(officesList && selectedWeek && selectedDay && !selectedOffice) {
      officesList.map(office => {
        setOfficesDailyData(office.ID, selectedDay.format("YYYY-MM-DD"));
      })
    } else if(officesList && selectedWeek && selectedDay && selectedOffice) {
      setOfficesDailyData(selectedOffice, selectedDay.format("YYYY-MM-DD"));
    }
  }, [officesList, selectedOffice, selectedWeek, selectedDay])

  console.log(selectedOffice, selectedWeek);

  useEffect(() => {
    if(!officesWeekData) return
    const summedOfficesWeekData = officesWeekData.sort((a, b) => a.key - b.key).reduce((acc, obj) => {
      acc.plan += obj.plan || 0;
      acc.peopleCount += obj.peopleCount || 0;
      acc.resultUSDT += obj.resultUSDT || 0;
      acc.resultRUB += obj.resultRUB || 0;
      acc.withdrawal += obj.withdrawal || 0;
      acc.peopleClosed += obj.peopleClosed || 0;
      return acc;
    }, { plan: 0, peopleCount: 0, resultUSDT: 0, resultRUB: 0, withdrawal: 0, peopleClosed: 0, });

    setCommonStatistic(summedOfficesWeekData)
  }, [officesWeekData])

  const mergedOfficesWeekData = Object.values(
    officesWeekData.sort((a, b) => a.key - b.key).reduce((acc, item) => {
      if (!acc[item.officeId]) {
        acc[item.officeId] = {
          officeId: item.officeId,
          name: item.name,
          plan: 0,
          peopleCount: 0,
          resultUSDT: 0,
          resultRUB: 0,
          withdrawal: 0,
          peopleClosed: 0,
        };
      }

      acc[item.officeId].plan += item.plan;
      acc[item.officeId].peopleCount += item.peopleCount;
      acc[item.officeId].resultUSDT += item.resultUSDT;
      acc[item.officeId].resultRUB += item.resultRUB;
      acc[item.officeId].withdrawal += item.withdrawal;
      acc[item.officeId].peopleClosed += item.peopleClosed;

      return acc;
    }, {})
  );

  return (
    <Layout section={"admin"}>
      <div className="box">
        <div className={`result`}>
          <h4 className="title">
            <span className="total">
              <IconPresentationChart width="25" height="25" fill="#00c39a" />
              Результаты
            </span>
          </h4>

          <Divider />

          <div className="filter">
            <DatePickerUi
              name="week"
              label="Выберите неделю"
              picker="week"
              onChange={handleWeekChange}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <DatePickerUi
              name="day"
              label="Выберите дату"
              disabled={!isDayPickerActive}
              disabledDate={disableDay}
              onChange={(date) => setSelectedDay(date)}
              errorMess={`Пожалуйста, выберите неделю`}
            />
            <SelectUi
              name={"office"}
              label={`Выберите Офис`}
              placeholder="Выберите Офис"
              onChange={(value) => setSelectedOffice(value)}
            >
              {
                officesList && officesList.map(office => (
                  <Option value={office.ID}>{office.Name}</Option>
                ))
              }
            </SelectUi>
          </div>

          <h5>Общая статистика:</h5>
          <div className="stat">
            <div className="item">
              <span>Общее кол-во людей</span>
              <b>{commonStatistic ? commonStatistic.peopleCount || 0 : "-"}</b>
            </div>
            <div className="item">
              <span>Общее кол-во закрытых клиентов</span>
              <b>{commonStatistic ? commonStatistic.peopleClosed || 0 : "-"}</b>
            </div>
            <div className="item">
              <span>Результат в USDT</span>
              <b>{commonStatistic ? commonStatistic.resultUSDT || `0$` : "-"}</b>
            </div>
            <div className="item">
              <span>Результат в RUB</span>
              <b>{commonStatistic ? commonStatistic.resultRUB || `0₽` : "-"}</b>
            </div>
            <div className="item">
              <span>Вывод в USDT</span>
              <b>{commonStatistic ? commonStatistic.withdrawal || `0$` : "-"}</b>
            </div>
          </div>

          <Divider />

          <Table
            tableLayout="auto"
            dataSource={mergedOfficesWeekData.length ? mergedOfficesWeekData : []}
            columns={columns}
            scroll={{ x: 768 }}
          />

          <Row>
            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                className={`result-box ${
                  !selectedWeek && !selectedOffice ? "inactive" : ""
                }`}
              >
                <h4>График кол-ва людей за неделю</h4>

                {!selectedWeek && !selectedOffice ? (
                  <div className="no-week-message">
                    <IconPresentationChart
                      width="55"
                      height="55"
                      fill="#666768"
                    />
                    Выберите неделю, чтобы увидеть графики.
                  </div>
                ) : (
                  <div className="chart">
                    <PeopleChart chartData={chartData} officesWeekData={officesWeekData} officesList={officesList} />
                  </div>
                )}
              </div>
            </Col>

            <Col xl={12} lg={12} md={24} sm={24} xs={24}>
              <div
                className={`result-box ${
                  !selectedWeek && !selectedOffice ? "inactive" : ""
                }`}
              >
                <h4>График результата за неделю</h4>

                {!selectedWeek && !selectedOffice ? (
                  <div className="no-week-message">
                    <IconPresentationChart
                      width="55"
                      height="55"
                      fill="#666768"
                    />
                    Выберите неделю, чтобы увидеть графики.
                  </div>
                ) : (
                  <div className="chart">
                    <ResultChart chartData={chartData} officesWeekData={officesWeekData} officesList={officesList} />
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <AlertUi
            type="info"
            text={`Чтобы увидеть результаты на графике, выберите неделю и офис, для которого хотите просмотреть данные.`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Results;
