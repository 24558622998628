import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Form, Modal, Select } from "antd";
import { IconClose } from "../../utils/Icons";
import { DatePickerUi, InputUi, SelectUi } from "../ui";
import { DateTime } from 'luxon';
import { getOfficeRequest, setOfficePlanRequest } from "../../models/office/office";

const { Option } = Select;

const EditPlanModal = ({ isModalOpen, fetchPlan, handleCancel }) => {
  const [form] = Form.useForm();

  const [officesList, setOfficesList] = useState(null);

  useEffect(() => {
    getOfficeRequest().then(({data}) => setOfficesList(data))
  }, [])

  const successForm = () => {
    setOfficePlanRequest(Number(form.getFieldValue('office')), form.getFieldValue('week').startOf('week').format('YYYY-MM-DDTHH:mm:ss[Z]'), Number(form.getFieldValue('usdt'))).then(() => {
      console.log('Changed');
      fetchPlan(); // refetch
    }).catch(err => {
      console.warn("Error setOfficePlanRequest", err);

      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Set office plan error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    });
    handleCancel();
    form.resetFields();
  };

  const errorForm = () => {
    console.error("Error");
  };

  return (
    <Modal
      title="Редактировать план"
      open={isModalOpen}
      onCancel={handleCancel}
      className="modal-closed"
      closeIcon={<IconClose width="21px" height="21px" fill="#d94c48" />}
      footer={null}
    >
      <Form
        form={form}
        name="editPlan"
        layout="vertical"
        onFinish={successForm}
        onFinishFailed={errorForm}
        autoComplete="off"
      >
        <SelectUi
          name={"office"}
          label={`Выберите офис`}
          placeholder={`Выберите офис`}
        >
          {
            officesList && officesList.map(({ID, Name}) => (
              <Option value={ID}>{Name}</Option>
            ))
          }
        </SelectUi>

        <DatePickerUi
          name="week"
          label="Выберите неделю"
          picker="week"
          errorMess={`Пожалуйста, выберите неделю`}
        />
        <InputUi
          name="usdt"
          label="Введите Сумму (USDT)"
          placeholder={`1000`}
          errorMess={`Пожалуйста, введите сумму в USDT`}
        />

        <div className="flex justify-center gap-10 mt-30">
          <Form.Item shouldUpdate>
            {() => (
              <Button
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                className="xs"
              >
                Сохранить
              </Button>
            )}
          </Form.Item>
          <Button onClick={handleCancel} className="xs">
            Закрыть
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditPlanModal;
