import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Button, Col, Form, Row } from "antd";
import Cookies from 'universal-cookie';
import { useHistory } from "react-router-dom";
import { loginRequest } from "../models/auth/auth";
import { InputUi } from "../components/ui";
import { IconLogIn } from "../utils/Icons";
import { validateJWTRequest } from "../models/auth/auth";

const Auth = () => {
  const [form] = Form.useForm();

  const cookies = new Cookies();
  const history = useHistory();

  const [state, setState] = useState({});

  const accessTokenExpirationDate = new Date();
  accessTokenExpirationDate.setDate(accessTokenExpirationDate.getDate() + 30);

  const successForm = () => {
    if(!state.login || !state.password) console.error("Login or Password isn\'t set")
    loginRequest(state.login, state.password).then(({data}) => {
      if(data) {
        cookies.set('at', data, {
          path: '/',
          expires: accessTokenExpirationDate,
          domain: document.location.hostname,
        });

        validateJWTRequest(data).then(({data}) => {
          if(data.UserRole === 'admin') {
            history.push("/admin/moderators");
          } else if(data.UserRole === 'moderator') {
            history.push("/user");
          } else {
            history.push("/admin/moderators");
          }
        })        
      }
    }).catch(err => {
      console.warn("Error loginRequest", err);
  
      // Check if the error is an Axios error to get a more descriptive message
      const errorMessage = err.response ? err.response.data.message || "Login error" : "Network error";
  
      // Show error toast
      toast.error(errorMessage);
    })
  };

  const errorForm = () => {
    console.error("Error");
  };

  const onChange = (evt) => {
    setState((prevState) => ({
      ...prevState,
      [evt.target.name]: evt.target.value,
    }));
  };

  useEffect(() => {
    const accessToken = cookies.get('at');

    if(accessToken) {
      console.log('accessToken', accessToken)
      validateJWTRequest(accessToken).then(({data}) => {
        if(data.UserRole === 'admin') {
          history.push("/admin/moderators");
        } else if(data.UserRole === 'moderator') {
          history.push("/user");
        } else {
          history.push("/admin/moderators");
        }
      }).catch(err => console.warn("validateJWTRequest error: ", err))
    }
  }, [])

  return !cookies.get('at') && (
    <section className="auth">
      <Row justify="center">
        <Col xl={12} lg={12} md={20} sm={24} xs={24}>
          <div className="logo">
            <img src={require("../assets/img/logo.svg").default} alt="" />
            <h1>Main crm</h1>
          </div>
          <div className="form">
            <Form
              form={form}
              name="auth"
              layout="vertical"
              onFinish={() => successForm()}
              onFinishFailed={() => errorForm()}
              autoComplete="off"
              onChange={onChange}
            >
              <InputUi
                name={`login`}
                label={`Введите логин`}
                placeholder={`Введите Ваш логин`}
                errorMess={`Пожалуйста, введите Ваш логин`}
              />

              <InputUi
                name="password"
                type="password"
                label={`Введите пароль`}
                placeholder="*******"
                errorMess={`Пожалуйста, введите Ваш пароль`}
              />

              <div className="flex justify-center mt-40">
                <Button htmlType="submit">
                  Войти
                  <IconLogIn width="24" height="24" fill="#fff" />
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default Auth;
